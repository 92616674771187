import logo from './logo.svg';
import './App.css';
import Main from './Components/MainComp/Main';
import Login from './Components/AuthComp/Login/Login';
import Routers from './Routers/Routers';

function App() {
  return (
    <div className="App">
      {/* <Main /> */}
      {/* <Login /> */}
      <Routers />
    </div>
  );
}

export default App;



